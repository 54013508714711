exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activate-tsx": () => import("./../../../src/pages/activate.tsx" /* webpackChunkName: "component---src-pages-activate-tsx" */),
  "component---src-pages-awards-tsx": () => import("./../../../src/pages/awards.tsx" /* webpackChunkName: "component---src-pages-awards-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-distribution-tsx": () => import("./../../../src/pages/distribution.tsx" /* webpackChunkName: "component---src-pages-distribution-tsx" */),
  "component---src-pages-domaine-tsx": () => import("./../../../src/pages/domaine.tsx" /* webpackChunkName: "component---src-pages-domaine-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internal-tsx": () => import("./../../../src/pages/internal.tsx" /* webpackChunkName: "component---src-pages-internal-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-marketing-tsx": () => import("./../../../src/pages/marketing.tsx" /* webpackChunkName: "component---src-pages-marketing-tsx" */),
  "component---src-pages-recipes-tsx": () => import("./../../../src/pages/recipes.tsx" /* webpackChunkName: "component---src-pages-recipes-tsx" */),
  "component---src-pages-wines-tsx": () => import("./../../../src/pages/wines.tsx" /* webpackChunkName: "component---src-pages-wines-tsx" */),
  "component---src-templates-md-basic-tsx": () => import("./../../../src/templates/md-basic.tsx" /* webpackChunkName: "component---src-templates-md-basic-tsx" */),
  "component---src-templates-md-media-article-tsx": () => import("./../../../src/templates/md-media-article.tsx" /* webpackChunkName: "component---src-templates-md-media-article-tsx" */),
  "component---src-templates-md-media-tsx": () => import("./../../../src/templates/md-media.tsx" /* webpackChunkName: "component---src-templates-md-media-tsx" */),
  "component---src-templates-md-recipe-tsx": () => import("./../../../src/templates/md-recipe.tsx" /* webpackChunkName: "component---src-templates-md-recipe-tsx" */),
  "component---src-templates-md-wine-tsx": () => import("./../../../src/templates/md-wine.tsx" /* webpackChunkName: "component---src-templates-md-wine-tsx" */)
}

